import {useStore} from 'effector-react';
import {Helmet} from 'react-helmet-async';
import $appStore from '../../store/app/store';
import {useLocation} from 'react-router-dom';
import ReactGA from 'react-ga4';
import {useEffect} from 'react';

export const Seo = () => {
  const appStore = useStore($appStore);
  const location = useLocation();

  useEffect(() => {
    if (appStore.gtm && !ReactGA.isInitialized) {
      ReactGA.initialize(appStore.gtm);
    }
    if (ReactGA.isInitialized) {
      ReactGA.send({hitType: 'pageview', page: location.pathname});
    }
  }, [appStore.gtm, location.pathname]);

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{appStore.siteTitle}</title>
      <meta name="description" content={appStore.siteDescription} />
    </Helmet>
  );
};
