import {useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';
import {useStore} from 'effector-react';
import $appStore from '../../store/app/store';
import {Header} from '../Header';
import {Footer} from '../Footer';
import {Loader} from '../Loader';
import {useNavigate} from 'react-router-dom';
import {Modal, Version} from '../';
import {
  setGtm,
  setIsLoading,
  setLanguage,
  setLanguages,
  setNavigation,
  setSiteType,
  setTag,
} from '../../store/app/events';
import {getContent} from '../../service/httpService';
import config from '../../config/config.json';
import './Layout.scss';
import {SiteType} from '../../store/app/types';

export const Layout = () => {
  const appStore = useStore($appStore);
  const navigator = useNavigate();
  const storedLanguage = sessionStorage.getItem('language');

  // const [modalIsOpened, setModalIsOpened] = useState(false);

  // useEffect(() => {
  //   if (!modalIsOpened && !sessionStorage.getItem('downloadModal')) {
  //     setModalIsOpened(true);
  //   } else {
  //     // setModalIsOpened(false);
  //   }
  // }, [modalIsOpened]);
  useEffect(() => {
    const getGtm = async () => {
      const data: any = await getContent('b2b', 'GET', `content/${config.appName}/settings`, null, '');
      setGtm(data.items[0].data.googleTagManager);
    };
    getGtm();
  }, []);

  useEffect(() => {
    const getLanguages = async () => {
      const data: any = await getContent(appStore.siteType, 'GET', `apps/${config.appName}/languages`, null, '');
      const defaultLanguage = data.items.filter((e: any) => e.isMaster);

      setLanguage(defaultLanguage ? defaultLanguage[0].lang2Code : 'en');
      sessionStorage.setItem('language', 'en');

      setIsLoading(false);
    };
    if (storedLanguage === null || storedLanguage === 'undefined') {
      getLanguages();
    } else {
      setLanguage(storedLanguage);
    }
  }, [appStore.languages, appStore.language, appStore.siteType, storedLanguage]);

  useEffect(() => {
    const pathArray = window.location.pathname.split('/');

    if (pathArray[1] === 'cool-one') {
      setSiteType('b2c');
    } else {
      setSiteType('b2b');
    }
  }, [window.location.pathname]);

  return (
    <div
      className={`layout ${appStore.navIsOpen ? 'navIsOpen' : ''} ${appStore.isMobile ? 'isMobile' : ''} ${
        appStore.isLoading ? 'loading' : ''
      } ${appStore.isMobile ? 'isMobile' : ''}`}
    >
      {appStore.language && <Header />}
      <div className={`main ${appStore.isLoading ? ' fadeIn' : ''}`}>{appStore.language && <Outlet />}</div>
      <Footer />
      {appStore.isLoading && <Loader />}
      {appStore.modalIsOpened && <Modal />}
      {/* <Version /> */}
    </div>
  );
};
