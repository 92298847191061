import React, {useEffect, useState, useRef} from 'react';

import {Link} from 'react-router-dom';
import {Nav} from './components';
import {BurgerMenu} from './components/BurgerMenu';
import config from '../../config/config.json';

import {useStore} from 'effector-react';
import $appStore from '../../store/app/store';
import useOnClickOutside from '../../hooks/useOnClickOutside';

import './Header.scss';
import {setLanguage, setModalIsOpened, setNavIsOpen, setNavIsOpened, setNavigation} from '../../store/app/events';
import {NavigationItem, SiteType} from '../../store/app/types';
import {getContent} from '../../service/httpService';
export const Header = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const appStore = useStore($appStore);
  const [direction, setDirection] = useState('up');
  const ref = useRef(null);
  const storedLanguage = sessionStorage.getItem('language');

  useEffect(() => {
    const getNavigation = async (language: string, siteType: SiteType) => {
      const data: any = await getContent(
        appStore.siteType,
        'GET',
        `content/${siteType === 'b2b' ? config.b2bConfig.appName : config.b2cConfig.appName}/navigation`,
        null,
        language,
      );
      // setLanguage(defaultLanguage ? defaultLanguage[0].lang2Code : 'en');
      setNavigation({
        headerMenu: data.items[0].data.headerMenu,
        footerB2cMenu: data.items[0].data.footerB2cMenu,
        footerMenu: data.items[0].data.footerMenu,
        legalMenu: data.items[0].data.legalMenu,
      });
    };
    if (storedLanguage !== null && appStore.language) {
      getNavigation(`${appStore.language ? appStore.language : storedLanguage}`, appStore.siteType);
    }
  }, [appStore.siteType, appStore.language, storedLanguage]);

  const handleClickOutside = () => {
    setNavIsOpen(false);
  };

  useOnClickOutside(ref, handleClickOutside);
  let oldScrollY = 0;

  const controlDirection = () => {
    if (window.scrollY > oldScrollY) {
      setDirection('down');
    } else {
      setDirection('up');
    }
    oldScrollY = window.scrollY;
  };

  useEffect(() => {
    window.addEventListener('scroll', controlDirection);
    return () => {
      window.removeEventListener('scroll', controlDirection);
    };
  }, []);

  useEffect(() => {
    window.onscroll = function (i: any) {
      const currentScrollPos = window.pageYOffset;
      if (currentScrollPos > 60) {
        setIsCollapsed(true);
      } else {
        setIsCollapsed(false);
      }
    };
  }, []);
  return (
    <>
      {appStore.language && (
        <>
          <header className={`${isCollapsed ? 'isCollapsed' : ''}`}>
            <div className="container">
              <div className="brand">
                <Link to="/" title="Meds2Go">
                  <svg className="icon">
                    <use xlinkHref="#logo" />
                  </svg>
                </Link>
              </div>
              <div className="menu">
                <div className="menuItems">
                  {appStore.navigation?.headerMenu.map((item: NavigationItem, i: number) => (
                    <Link to={item.slug} className="menuItem" key={i}>
                      {item.title}
                    </Link>
                  ))}
                </div>
              </div>
              <div className="language">
                <div className="dropdown">
                  <span className="language-select">{appStore.language === 'en' ? 'English' : 'Netherlands'}</span>
                  <div className="dropdown-content">
                    <div onClick={() => setLanguage('en')}>English</div>
                    <div onClick={() => setLanguage('nl')}>Netherlands</div>
                  </div>
                </div>
              </div>
              <div className="mobile-nav">
                <div
                  className={`nav-icon ${appStore.navIsOpen ? 'navIsOpen' : ''}`}
                  onClick={() => setNavIsOpen(!appStore.navIsOpen)}
                >
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div className="mobile-menu" ref={ref}>
                  <div className="lang-list">
                    <div>LANGUAGE:</div>

                    <div onClick={() => setLanguage('en')}>English</div>
                    <div onClick={() => setLanguage('nl')}>Netherlands</div>
                  </div>
                  <div className="menu-list">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      {appStore.navigation?.headerMenu.map((item: NavigationItem, i: number) => (
                        <li key={i}>
                          <Link to={item.slug} className="">
                            {item.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="divider-bg"></div>
            <div className="divider shadow"></div>
          </header>
          <div className={`mobileNav ${appStore.navIsOpen ? 'navIsOpen' : ''}`}>
            <Nav />
          </div>
        </>
      )}
    </>
  );
};
