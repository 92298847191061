import React from 'react';
import {Link} from 'react-router-dom';
import {settings} from '../../common';
import {TeaserType, TeasersType} from '../types/homeTypes';

interface ITeasers {
  teasers: TeasersType;
}

export const Teasers = (props: ITeasers) => {
  const {teasers} = props;

  return (
    <section className="teasers" id="cold-chain">
      <div className="container">
        <div className="help-title">{teasers.sectionTeasersTitle}</div>
        <div className="boxes">
          {teasers &&
            teasers.teasers.length > 0 &&
            teasers.teasers.map((item: TeaserType, i: number) => (
              <div className={`box bg-${item.bgColor.toLowerCase()} fadeIn`} key={i}>
                <div className="box-image ">
                  <img src={`${settings.assetsURL}/${item.image[0]}`} alt="" />
                </div>
                <div className="box-content">
                  <div className="box-title">{item.title}</div>
                  <div className="box-text" dangerouslySetInnerHTML={{__html: item.text}} />
                  <div className="box-button">
                    {!item.buttonLink.includes('http') ? (
                      <Link to={item.buttonLink} className="btn btn-primary">
                        {item.buttonText}
                      </Link>
                    ) : (
                      <a href={item.buttonLink} className="btn btn-primary">
                        {item.buttonText}
                      </a>
                    )}
                  </div>
                </div>
                <div className="divider shadow"></div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};
